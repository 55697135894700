:root{
    --theme-primary-color: rgb(26, 87, 128);
    --theme-accent-color: rgb(26, 128, 80);


    --theme-light-background-color: rgb(230, 230, 230);
    --theme-dark-background-color: rgb(41, 41, 41);


    --theme-trans-light: rgba(255, 255, 255, 15%);
    --theme-trans-lighter: rgba(255, 255, 255, 30%);
    --theme-trans-lightest: rgba(255, 255, 255, 50%);
    --theme-trans-dark: rgba(0, 0, 0, 15%);
    --theme-trans-darker: rgba(0, 0, 0, 30%);
    --theme-trans-darkest: rgba(0, 0, 0, 50%);

    --theme-transition-speed: .3s;
    --theme-border-radius: .5em;
    --theme-default-padding: .2em .5em;
    --theme-default-padding-large: .5em 1em;
}
