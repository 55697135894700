
                        @import "~/_globals.scss";
                        
@import "normalize";
@import "reset";
@import "theme";
@import "datepicker_overrides";

$headerHeight: 50px;
$footerHeight: 1.2em;

/********************** STRUCTURE **********************/
html{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#app{
    
    height: 100vh;
    width: 100vw;
    display:grid;
    grid-template-rows: $headerHeight auto $footerHeight;
    grid-template-areas: 
                    "header"
                    "content"
                    "footer";

}
#header { 
    z-index: 2;
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: .5em 1em;

    #header-content{
        font-weight: 600;
    }
}
#footer { 
    z-index: 2;
    grid-area: footer;
    display: flex;
    justify-content: flex-end;

    .attribution{
        margin: 0 .5em;
    }
}




#map {
    grid-area: content;
    position: relative;
    height: 100%;
    width: 100%;
}

$filtersPadding: 2em;

#filters {
    position: absolute;
    display:flex;
    align-items: stretch;
    z-index: 1;
    //height: 100px;
    max-height: calc(100vh - $filtersPadding*2 - $headerHeight - $footerHeight);

    min-width: 410px;
    max-width: 25vw;
    // height:  calc(70vh - $headerHeight - $footerHeight);
    bottom: $filtersPadding;
    right: 0;
    
}


/********************** Styling **********************/
html{
    background-color: #fff;
}

#app{
    
    background: rgb(171, 203, 211);
    

}
#header { 
    background-color: #fff;
    border-bottom: 2px solid rgba(0,0,0,50%);
    box-shadow: 0px 2px 12px rgba(0,0,0,30%);
}
#footer { 
    background-color: #333;
    
    color: #eee;
    text-align: right;
    font-size: .7em;
    padding: .2em .5em;
}

#map {
    
}

#filters {
    // background-color: #80808055;
}


// button {
//     @include button;
// }


@import 'featureModal'