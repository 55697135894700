@mixin button() {
    background: var(--theme-primary-color);
    color: #fff;
    border: none;
    border-radius: var(--theme-border-radius);
    font-weight: 600;
    padding: var(--theme-default-padding-large) ;

    transition: background-color var(--theme-transition-speed);

    &:hover{
        cursor: pointer;
        background-color: var(--theme-accent-color);
    }
    
}

@mixin material-shadow($spread:5px , $strength: 15%) {
    box-shadow: 
        // 0 0 15px 5px var(--theme-trans-light) inset,
        $spread $spread $spread 0px rgba(0,0,0, $strength) ,
        #{-($spread)} #{-($spread)} $spread 0px rgba(255,255,255, $strength) ,
        ;
}