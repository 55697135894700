.dataTable {
    --border-color: #ccc;
    --border-radius: .5em;

    width: 100%;
    // background: red
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    
    tr{
        &:first-child{
            th,td {
                &:first-child{
                    border-top-left-radius: var(--border-radius);
                }
                &:last-child{
                    border-top-right-radius: var(--border-radius);
                }                
            }
            
        }
        &:last-child{
            th,td {
                &:first-child{
                    border-bottom-left-radius: var(--border-radius);
                }
                &:last-child{
                    border-bottom-right-radius: var(--border-radius);
                }                
            }
            
        }
    }


    th,td {
        border: 1px solid var(--border-color);
    }
}