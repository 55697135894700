
                        @import "~/_globals.scss";
                        
.toastify {



    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .8em;
    padding: .5em;
    border-radius: .3em;
    box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 
                0 10px 36px -4px rgba(0, 0, 0, 0.3);
    
    z-index: 1;
    
    color: #fff;            
    background: #147da7;
    
    &.warning{
        background: #f0a83b;
    }
    &.error{
        background: rgb(240, 46, 46);
    }

    &.success {
        background: rgb(9, 116, 9);
    }



}