
                        @import "~/_globals.scss";
                        

.CUSTOM_MODAL{

    text-align: left;
    // max-height: 90vh;

    // .swal2-container {
    //     // max-height: 90vh;
    // }

    
    .swal2-title {
        text-align: left;
    }
    
    

    .swal2-html-container {
        // max-height: 50%;
        // overflow:auto;
    }

    .swal2-actions {
        justify-content: right;
        width: 100%;
        padding: 0 1em;
        button{
            @include button;
            padding: var(--theme-default-padding-large);
        }
    }
}
