
                        @import "~/_globals.scss";
                        

.filters_container{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;
    padding: 0 .2em;
    // height: 100%;
    border-top-left-radius: var(--theme-border-radius);
    border-bottom-left-radius: var(--theme-border-radius);
    background: var(--theme-light-background-color);

    box-shadow: 0 0 10px 5px var(--theme-trans-darker);
    

    .controls_container {
        background-color: var(--theme-trans-light);
        // box-shadow: -5px -5px 5px 0px var(--theme-trans-lightest) ,
        //             5px 5px 5px 0px var(--theme-trans-darkest) ;
        
        @include material-shadow($strength:50%);

        border-radius: .5em;
        flex: 0 1 auto;
        padding: 5px;
        margin: 5px;

        button {
            @include button;
            margin: var(--theme-default-padding)
            
        }
    }

    .filters {
        overflow-y: auto;
        // flex-shrink: 1;
        // flex:1;
        flex: 0 1 auto; /* formerly flex: 1 0 auto; */
        padding-right: .5em;
        // overflow: auto; /* new */


        
        
    }
    
    
}

.filter {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background: var(--theme-trans-light);
    
    // border: 1px solid var(--theme-trans-light);
    border-radius: var(--theme-border-radius);

    transition: all .3s;
    margin:.3em;

    @include material-shadow($spread: .2em, $strength:20%);

    &:hover {
        background: var(--theme-trans-lightest);
    }

    .filter_toggle {
        display: flex;
        align-items: center;
        background: var(--theme-trans-darker);
        //border-radius: 1em;
        // height: 2em;
        width: 2em;
        transition: all .3s;

        border-top-left-radius: var(--theme-border-radius);
        border-bottom-left-radius: var(--theme-border-radius);
        .filter_toggle_icon {
            //display: none;
            opacity: 0;
            transition: all .3s;
        }

        &:hover {
            cursor: pointer;
            background: var(--theme-trans-dark);
        }

    }

    .filter_body_wrapper {
        display: flex;
        align-items: center;
        // background: #00000022;
        flex-grow: 1;
        padding: .2em .75em;


        .filter_icon {
            display: inline-block;
            height: 1em;
            width: 1em;
            margin: .3em;
            filter: brightness(40%);
        }

        .filter_name {
            text-align: left;
        }
    }


    &.active {
        .filter_toggle {
            
                background-color: var(--theme-accent-color);
    
                .filter_toggle_icon {
                    //display: block;
                    opacity: 1;
                }
    
    
                &:hover {
                    cursor: pointer;
                    background: limegreen;
                }
            
        }
    }
    
}

.filter[data-filter-id="dateRangeFilter"] {
    
    &:not(.active) {
        .dateRange_wrapper {display: none}

    }

    .dateRange_wrapper {
        // padding: var(--theme-default-padding)
        margin: .2em 0
    }

    .filter_range{ 
        margin: .2em 0;
    }
    .filter_help{
        width: 300px;
        font-size: .75em;
    }

}